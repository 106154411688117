import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139'),
	() => import('./nodes/140'),
	() => import('./nodes/141'),
	() => import('./nodes/142'),
	() => import('./nodes/143'),
	() => import('./nodes/144'),
	() => import('./nodes/145'),
	() => import('./nodes/146'),
	() => import('./nodes/147'),
	() => import('./nodes/148'),
	() => import('./nodes/149'),
	() => import('./nodes/150'),
	() => import('./nodes/151'),
	() => import('./nodes/152')
];

export const server_loads = [0,46,22,47,24,25,31,18,14,17,15,16,20,2,7,38,40,41,10,4,6,3];

export const dictionary = {
		"/(landings)/(home)": [~144,[46]],
		"/(hub)/admin": [87,[22]],
		"/(hub)/admin/impersonation": [~88,[22]],
		"/(hub)/admin/stats": [89,[23]],
		"/(hub)/admin/stats/teams": [~90,[23]],
		"/(iam)/auth/edu": [~136,[44]],
		"/(iam)/auth/invite": [~137],
		"/(iam)/auth/password": [~138],
		"/(iam)/auth/recover": [~139,[43]],
		"/(iam)/auth/signin": [~140,[43]],
		"/(iam)/auth/signup": [~141,[43]],
		"/(landings)/championship": [~145,[47]],
		"/(landings)/championship/disciplines": [146,[47]],
		"/(landings)/championship/documents": [147,[47]],
		"/(hub)/console": [91,[24]],
		"/(hub)/console/cms/[resource_id]/richtext": [92,[25]],
		"/(hub)/console/cms/[resource_id]/text": [~93,[26]],
		"/(hub)/console/codes": [~94,[24]],
		"/(hub)/console/codes/[id]": [~95,[27]],
		"/(hub)/console/crm/feedback": [~96,[24]],
		"/(hub)/console/crm/partnership": [~97,[24]],
		"/(hub)/console/crm/subscription": [~98,[24]],
		"/(hub)/console/organizations": [~99,[24]],
		"/(hub)/console/organizations/[id]": [~100,[28]],
		"/(hub)/console/organizations/[id]/groups": [~101,[28]],
		"/(hub)/console/organizations/[id]/groups/[group_id]": [~102,[29]],
		"/(hub)/console/organizations/[id]/groups/[group_id]/roles": [~103,[29]],
		"/(hub)/console/organizations/[id]/groups/[group_id]/terms": [~104,[29]],
		"/(hub)/console/organizations/[id]/roles": [~105,[28]],
		"/(hub)/console/terms": [~106,[24]],
		"/(hub)/console/terms/[id]": [~107,[30]],
		"/(hub)/console/terms/[id]/map": [108,[30]],
		"/(hub)/console/users": [~109,[24]],
		"/(hub)/dev": [110,[31],[32]],
		"/(hub)/dev/examples": [~111,[33]],
		"/(hub)/dev/examples/await": [112,[33]],
		"/(hub)/dev/examples/scheduler": [113,[33]],
		"/(hub)/dev/examples/seaweedfs": [114,[33]],
		"/(hub)/dev/examples/xapi": [115,[33]],
		"/(hub)/dev/examples/yjs": [116,[33]],
		"/(hub)/dev/landing": [117,[34]],
		"/(hub)/dev/playground": [~118,[35]],
		"/(hub)/dev/playground/buttons": [119,[35]],
		"/(hub)/dev/playground/confirm": [120,[35]],
		"/(hub)/dev/playground/dates": [121,[35]],
		"/(hub)/dev/playground/gantt": [122,[35]],
		"/(hub)/dev/playground/inputs": [~123,[35]],
		"/(hub)/dev/playground/modal": [124,[35]],
		"/(hub)/dev/playground/popover": [125,[35]],
		"/(hub)/dev/playground/switch": [126,[35]],
		"/(hub)/dev/playground/tabs copy": [128,[35]],
		"/(hub)/dev/playground/tabs": [127,[35]],
		"/(hub)/dev/playground/toasts": [129,[35]],
		"/(events)/events/edit/event/[event_id]": [~78,[18]],
		"/(events)/events/edit/showcase/[showcase_id]": [~79,[18]],
		"/(events)/events/[slug]/(showcase)": [75,[14,17]],
		"/(events)/events/[slug]/(showcase)/offers": [76,[14,17]],
		"/(events)/events/[slug]/(showcase)/schedule/[offer_id]": [77,[14,17]],
		"/(events)/events/[slug]/(event)/[event_id]/[[blueprint_id]]": [74,[14,15,16]],
		"/(home)/facilitator": [85,[20]],
		"/(landings)/faststart": [148],
		"/(education)/groups": [~58,[2]],
		"/(education)/groups/[id]": [~59,[7]],
		"/(education)/groups/[id]/terms": [~60,[7]],
		"/(home)/home": [~86,[20]],
		"/(home)/(invitations)/invitations_international": [~83,[20,21]],
		"/(home)/(invitations)/invitations_limited": [~84,[20,21]],
		"/(home)/(invitations)/invitations": [~82,[20,21]],
		"/legal/user-agreement": [152,[48]],
		"/(hub)/lms": [130,[36],[37]],
		"/(hub)/lms/[type=blueprint_type]": [~131,[36],[37]],
		"/(hub)/lms/[type=blueprint_type]/[root_id]/[[nested_id]]/content": [133,[38,40,41],[39]],
		"/(hub)/lms/[type=blueprint_type]/[root_id]/[[nested_id]]/materials": [134,[38,40],[39]],
		"/(hub)/lms/[type=blueprint_type]/[root_id]/[[nested_id]]": [~132,[38,40],[39]],
		"/(hub)/mentorship": [135,[42]],
		"/(landings)/opencup": [~149],
		"/(landings)/opencup/disciplines": [150],
		"/(landings)/opencup/documents": [151],
		"/(education)/org/[org_id]": [~61,[2]],
		"/(education)/org/[org_id]/events": [63,[9]],
		"/(education)/org/[org_id]/events/[term_id]/teams": [~64,[10]],
		"/(education)/org/[org_id]/events/[term_id]/teams/personal": [~66,[10]],
		"/(education)/org/[org_id]/events/[term_id]/teams/team": [67,[10]],
		"/(education)/org/[org_id]/events/[term_id]/teams/[group_id]": [~65,[10],[,11]],
		"/(education)/org/[org_id]/teams/[group_id]": [~68,[2]],
		"/(education)/org/[org_id]/[slug]": [~62,[8]],
		"/(external)/platform/alg": [~80],
		"/(external)/platform/standings/[standing_id]": [81,[19]],
		"/(education)/rewards": [~69,[2,12]],
		"/(education)/rewards/acknowledgments": [71,[2,12]],
		"/(education)/rewards/certificates": [~72,[2,12]],
		"/(education)/rewards/diplomas": [~73,[2,12]],
		"/(education)/rewards/[reward_id]": [~70,[2,12],[,,13]],
		"/(iam)/user": [~142,[45]],
		"/(iam)/user/security": [~143,[45]],
		"/(education)/[type=term_type]": [~49,[2]],
		"/(education)/[type=term_type]/international": [~53,[4]],
		"/(education)/[type=term_type]/international/events": [54,[5]],
		"/(education)/[type=term_type]/international/events/[term_id]/teams": [~55,[6]],
		"/(education)/[type=term_type]/international/events/[term_id]/teams/[group_id]": [~56,[6]],
		"/(education)/[type=term_type]/international/teams/[group_id]": [~57,[4]],
		"/(education)/[type=term_type]/[slug]": [~50,[3]],
		"/(education)/[type=term_type]/[slug]/teams/[group_id]": [~52,[3]],
		"/(education)/[type=term_type]/[slug]/[blueprint_id]": [~51,[3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: universal_hooks.reroute || (() => {}),
	transport: universal_hooks.transport || {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';